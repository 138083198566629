import React from "react";
import {
  Stack,
  Heading,
  Center,
  Image,
  Text,
  Box,
  VStack,
  Link,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { Markup } from "interweave";
import Paragraph from "../common/Paragraph";

const convertHTML = (node, children) => {
  if (node.tagName === "A") {
    return (
      <Link
        href={node.getAttribute("href")}
        color="teal"
        textDecoration="underline"
      >
        {children}
      </Link>
    );
  }

  if (node.tagName === "P") {
    return <Paragraph>{children}</Paragraph>;
  }
};

const NewsPage = (props) => {
  const { newsItem } = props;

  const { title, content, image, updated } = newsItem;

  return (
    <Center>
      <Stack
        minHeight="800px"
        paddingTop="100px"
        w={["75%", "75%", "75%", "50%"]}
      >
        <Heading fontFamily="Noto sans, sans-serif">
          <Markup content={title} />
        </Heading>
        <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="xs"
          ml="2"
          alignItems="center"
        >
          {new Date(Number.parseInt(updated)).toLocaleDateString()}
        </Box>
        <VStack>
          <Image
            boxShadow="md"
            src={image.url}
            maxWidth="800px"
            maxHeight="500px"
            minWidth="500px"
          />

          <Text
            fontSize="lg"
            paddingTop="1em"
            textAlign="justify"
            fontFamily="Noto Serif, serif"
          >
            <Markup content={content} transform={convertHTML} />
          </Text>
        </VStack>
      </Stack>
    </Center>
  );
};

NewsPage.propTypes = {
  newsItem: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.shape({ url: PropTypes.string }),
  }).isRequired,
};

export default NewsPage;
